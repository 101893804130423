import { cn } from "@/lib/utils.ts";
import type { Variants } from "framer-motion";
import { motion } from "framer-motion";
import type React from "react";
import { usePopperTooltip } from "react-popper-tooltip";

const popupVariant: Variants = {
	offscreen: {
		scale: 0.5,
		opacity: 0,
	},
	onscreen: {
		scale: 1,
		opacity: 1,
		transition: {
			type: "spring",
			stiffness: 3000,
			damping: 70,
			mass: 3,
		},
	},
};

const tooltipDelayConfig = {
	instant: 0,
	quick: 100,
	short: 500,
	long: 1000,
};

export const Tooltip = ({
	tooltip = undefined,
	tooltipDelay = "quick",
	tooltipPosition = "bottom",
	tooltipClassName = "whitespace-nowrap border rounded-md bg-white shadow-sm px-2 py-1",
	tooltipWrapperClassName = "",
	tooltipOffset = [0, 6],
	children,
	className = "",
}: {
	children: React.ReactNode;
	tooltip?: React.ReactNode;
	tooltipDelay?: "instant" | "long" | "short" | "quick";
	tooltipPosition?:
		| "auto"
		| "auto-start"
		| "auto-end"
		| "top"
		| "top-start"
		| "top-end"
		| "bottom"
		| "bottom-start"
		| "bottom-end"
		| "right"
		| "right-start"
		| "right-end"
		| "left"
		| "left-start"
		| "left-end";
	tooltipClassName?: string;
	className?: string;
	tooltipWrapperClassName?: string;
	tooltipOffset?: [number, number];
}) => {
	const {
		getArrowProps,
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible,
	} = usePopperTooltip({
		delayShow: tooltipDelayConfig[tooltipDelay],
		placement: tooltipPosition,
		offset: tooltipOffset,
	});

	return (
		<div ref={setTriggerRef} className={cn(className)}>
			{children}
			{visible && tooltip && (
				<div
					ref={setTooltipRef}
					{...getTooltipProps({
						className: cn({
							"z-10": true,
							[tooltipWrapperClassName]: true,
						}),
					})}
				>
					<motion.div
						initial="offscreen"
						whileInView="onscreen"
						variants={popupVariant}
						className={cn({
							"z-10": true,
							[tooltipClassName]: true,
						})}
					>
						{tooltip}
						{/*<div {...getArrowProps({ className: `tooltip-arrow` })} /*/}
					</motion.div>
				</div>
			)}
		</div>
	);
};
